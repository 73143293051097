@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.menu {
  color: #858585;
  font-size: 16px;
  font-weight: 400;
}

.active {
  color: #c0561d;
  font-size: 16px;
  font-weight: 700;
}

.prev .next {
  color: #c0561d;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}
